<template>
    <custom-table
        :title="$t('pages.ecommerce.user.address.title')"
        :subTitle="$t('pages.ecommerce.user.address.subTitle')"
        rowKey="id"
        :items="table.data"
        :loading="table.loading"
        selectableRows
        :columns="fields"
        :actions="actions"
        :pagination="table.pagination"
        @action="handleClickAction"
        @selectableRows="handleSelectedRow"
        @changeTable="handleTableChange">
        <template v-slot:type="{ row: record }">
            {{ getText(record.invoice_type_id, 'type')  }}
        </template>
        <template v-slot:defaultAddress="{ row: record }">
            <span :class="record.is_default ? 'badge-light-success': 'badge-light-danger'" class="badge">{{ record.is_default ? $t('common.yes') : $t('common.no') }}</span>
        </template>
        <template v-slot:actions="{ row: record }">
            <div class="d-flex justify-content-end">
                <a v-on:click="fetchUserAddress(record)" class="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1">
                    <span class="svg-icon svg-icon-3">
                        <inline-svg src="/media/icons/duotune/art/art005.svg"/>
                    </span>
                </a>
                <el-popconfirm :title="$t('messages.sureDelete')" :confirm-button-text="$t('btn.yes')" :cancel-button-text="$t('btn.no')" @confirm="deleteRecord([record.id])">
                    <template #reference>
                        <a class="btn btn-icon btn-bg-light btn-active-color-primary btn-sm">
                            <span class="svg-icon svg-icon-3">
                                <inline-svg src="/media/icons/duotune/general/gen027.svg"/>
                            </span>
                        </a>
                    </template>
                </el-popconfirm>
            </div>
        </template>
    </custom-table>
    <div class="modal fade" id="kt_modal_add_user_address" ref="addUserAddressModal" tabindex="-1" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered mw-650px">
            <div class="modal-content">
                <div class="modal-header" id="kt_modal_add_user_address_header">
                    <h2 class="fw-bolder">{{ form.title }}</h2>
                    <div id="kt_modal_add_customer_close" data-bs-dismiss="modal" class="btn btn-icon btn-sm btn-active-icon-primary">
                        <span class="svg-icon svg-icon-1">
                            <inline-svg src="/media/icons/duotune/arrows/arr061.svg"/>
                        </span>
                    </div>
                </div>
                <el-form @submit.prevent="onSubmit()" :model="form.data" ref="userAddressForm">
                    <div class="modal-body py-10 px-lg-17">
                        <div class="scroll-y me-n7 pe-7" id="kt_modal_add_user_address_scroll" data-kt-scroll="true" data-kt-scroll-activate="{default: false, lg: true}" data-kt-scroll-max-height="auto"
                             data-kt-scroll-dependencies="#kt_modal_add_customer_header" data-kt-scroll-wrappers="#kt_modal_add_customer_scroll" data-kt-scroll-offset="300px">
                            <div class="fv-row mb-7">
                                <label class="required fs-6 fw-bold mb-2">{{ $t('pages.ecommerce.user.address.cols.type') }}</label>
                                <el-form-item prop="active">
                                    <el-radio-group v-model="form.data.invoice_type_id">
                                        <el-radio-button :label="1">{{ $t("common.individual") }}</el-radio-button>
                                        <el-radio-button :label="2">{{ $t("common.corporate") }}</el-radio-button>
                                    </el-radio-group>
                                </el-form-item>
                            </div>

                            <div class="fv-row mb-7">
                                <label class="required fs-6 fw-bold mb-2">{{ $t('pages.ecommerce.user.address.cols.title') }}</label>
                                <el-form-item prop="title" :rules="$validation.getMessage(['required'])">
                                    <el-input v-model="form.data.title" type="text"/>
                                </el-form-item>
                            </div>

                            <div class="fv-row mb-7">
                                <label class="required fs-6 fw-bold mb-2">{{ $t('pages.ecommerce.user.address.cols.country') }}</label>
                                <el-form-item prop="country_id" :rules="$validation.getMessage(['required'])">
                                    <el-select class="w-100" v-model="form.data.country_id" filterable remote :placeholder="$t('pages.ecommerce.user.address.searchCountry')" :remote-method="countryRemoteMethod" @change="countryChanged()">
                                        <el-option v-for="item in country.options" :key="item.value" :label="item.label" :value="item.value"></el-option>
                                    </el-select>
                                </el-form-item>
                            </div>

                            <div class="fv-row mb-7" v-if="form.data.country_id">
                                <label class="required fs-6 fw-bold mb-2">{{ $t('pages.ecommerce.user.address.cols.state') }}</label>
                                <el-form-item prop="state_id" :rules="$validation.getMessage(['required'])">
                                    <el-select class="w-100" v-model="form.data.state_id" filterable remote :placeholder="$t('pages.ecommerce.user.address.searchState')" :remote-method="stateRemoteMethod" @change="stateChanged()">
                                        <el-option v-for="item in country.stateOptions" :key="item.value" :label="item.label" :value="item.value"></el-option>
                                    </el-select>
                                </el-form-item>
                            </div>

                            <div class="fv-row mb-7" v-if="form.data.state_id">
                                <label class="required fs-6 fw-bold mb-2">{{ $t('pages.ecommerce.user.address.cols.city') }}</label>
                                <el-form-item prop="city_id" :rules="$validation.getMessage(['required'])">
                                    <el-select class="w-100" v-model="form.data.city_id" filterable remote :placeholder="$t('pages.ecommerce.user.address.searchCity')" :remote-method="cityRemoteMethod">
                                        <el-option v-for="item in country.cityOptions" :key="item.value" :label="item.label" :value="item.value"></el-option>
                                    </el-select>
                                </el-form-item>
                            </div>

                            <div class="fv-row mb-7">
                                <label class="required fs-6 fw-bold mb-2">{{ $t('pages.ecommerce.user.address.cols.district') }}</label>
                                <el-form-item prop="district" :rules="$validation.getMessage(['required'])">
                                    <el-input v-model="form.data.district" type="text"/>
                                </el-form-item>
                            </div>

                            <div class="fv-row mb-7">
                                <label class="fs-6 fw-bold mb-2">{{ $t('pages.ecommerce.user.address.cols.zipCode') }}</label>
                                <el-form-item prop="zip_code">
                                    <el-input v-model="form.data.zip_code" oninput="value=value.replace(/[^0-9.]/g,'')"/>
                                </el-form-item>
                            </div>

                            <div class="fv-row mb-7">
                                <label class="required fs-6 fw-bold mb-2">{{ $t('pages.ecommerce.user.address.cols.firstname') }}</label>
                                <el-form-item prop="firstname" :rules="$validation.getMessage(['required'])">
                                    <el-input v-model="form.data.firstname" type="text"/>
                                </el-form-item>
                            </div>

                            <div class="fv-row mb-7">
                                <label class="required fs-6 fw-bold mb-2">{{ $t('pages.ecommerce.user.address.cols.lastname') }}</label>
                                <el-form-item prop="lastname" :rules="$validation.getMessage(['required'])">
                                    <el-input v-model="form.data.lastname" type="text"/>
                                </el-form-item>
                            </div>

                            <template v-if="form.data.invoice_type_id == 2">
                                <div class="fv-row mb-7">
                                    <label class="required fs-6 fw-bold mb-2">{{ $t('pages.ecommerce.user.address.cols.companyName') }}</label>
                                    <el-form-item prop="company_name" :rules="$validation.getMessage(['required'])">
                                        <el-input v-model="form.data.company_name" type="text"/>
                                    </el-form-item>
                                </div>

                                <div class="fv-row mb-7">
                                    <label class="required fs-6 fw-bold mb-2">{{ $t('pages.ecommerce.user.address.cols.companyTaxOffice') }}</label>
                                    <el-form-item prop="company_tax_office" :rules="$validation.getMessage(['required'])">
                                        <el-input v-model="form.data.company_tax_office" type="text"/>
                                    </el-form-item>
                                </div>
                            </template>

                            <div class="fv-row mb-7">
                                <label class="required fs-6 fw-bold mb-2">{{ getText(form.data.invoice_type_id, 'identificationNumber') }}</label>
                                <el-form-item prop="identification_number" :rules="$validation.getMessage(['required'])">
                                    <el-input v-model="form.data.identification_number" v-mask="{ mask:'99999999999', placeholder: '' }" type="text"/>
                                </el-form-item>
                            </div>

                            <div class="fv-row mb-7">
                                <label class="required fs-6 fw-bold mb-2">{{ $t('pages.ecommerce.user.address.cols.mobilePhone') }}</label>
                                <el-form-item prop="mobile_phone" :rules="$validation.getMessage(['required'])">
                                    <el-input v-model="form.data.mobile_phone" v-mask="{ mask:'+\\90 599 999 99 99', placeholder: '' }" type="text"/>
                                </el-form-item>
                            </div>

                            <div class="fv-row mb-7">
                                <label class="required fs-6 fw-bold mb-2">{{ $t('pages.ecommerce.user.address.cols.address') }}</label>
                                <el-form-item prop="address" :rules="$validation.getMessage(['required'])">
                                    <el-input v-model="form.data.address" type="textarea" rows="3"/>
                                </el-form-item>
                            </div>

                            <div class="fv-row mb-7">
                                <label class="fs-6 fw-bold mb-2">{{ $t('pages.ecommerce.user.address.cols.defaultAddress') }}</label>
                                <el-form-item prop="active">
                                    <el-radio-group v-model="form.data.is_default">
                                        <el-radio-button :label="true">{{ $t("common.yes") }}</el-radio-button>
                                        <el-radio-button :label="false">{{ $t("common.no") }}</el-radio-button>
                                    </el-radio-group>
                                </el-form-item>
                            </div>
                        </div>
                    </div>

                    <div class="modal-footer flex-center">
                        <button :data-kt-indicator="form.loading ? 'on' : null" class="btn btn-lg btn-primary" type="submit" :disabled="form.loading">
                            <span v-if="!form.loading" class="indicator-label">{{ $t("btn.save") }}</span>
                            <span v-if="form.loading" class="indicator-progress">
                                {{ $t("messages.wait") }}
                                <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                            </span>
                        </button>
                    </div>
                </el-form>
            </div>
        </div>
    </div>
</template>

<script>
import CustomTable from "@/components/custom-table";

export default {
    name: "admin",
    props: ['userID'],
    components: {
        CustomTable
    },
    data() {
        return {
            fields: [
                {
                    name: this.$t("pages.ecommerce.user.address.cols.id"),
                    key: "id",
                    class: ""
                },
                {
                    name: this.$t("pages.ecommerce.user.address.cols.title"),
                    key: "title"
                },
                {
                    name: this.$t("pages.ecommerce.user.address.cols.type"),
                    scopedSlots: {customRender: "type"}
                },
                {
                    name: this.$t("pages.ecommerce.user.address.cols.defaultAddress"),
                    scopedSlots: {customRender: "defaultAddress"}
                },
                {
                    name: this.$t("common.action"),
                    key: "action",
                    scopedSlots: {customRender: "actions"}
                }
            ],
            actions: [
                {
                    name: "new",
                    icon: "bi-plus-lg",
                    label: "btn.add"
                },
                {
                    name: "refresh",
                    icon: "bi-arrow-repeat",
                    label: "btn.refresh"
                },
                {
                    name: "delete",
                    icon: "bi-trash",
                    label: "btn.delete"
                }
            ],
            form: {
                title: '',
                loading: false,
                updateStatus: false,
                data: {}
            },
            country: {
                options: [],
                stateOptions: [],
                cityOptions: []
            },
            states: {},
            cities: {},
            selectedRowKeys: []
        }
    },
    computed: {
        table() {
            return this.$store.state.ecommerce.user.address.table;
        },
        countries() {
            let countries = this.$store.state.localization.country.table.data;
            let returnData = {};

            if (countries.length) {
                countries.forEach((country) => {
                    returnData[country.id] = {
                        label: country.name,
                        value: country.id
                    }
                })
            }

            return returnData;
        }
    },
    mounted() {
        this.$store.dispatch('ecommerce/user/address/get', {
            page: {},
            filterData: {
                user_id: this.userID
            }
        });
        this.$store.dispatch('localization/country/get', {
            page: {pageSize: 9999}
        });
    },
    methods: {
        newUserAddress() {
            this.form.updateStatus = false;
            this.form.data = {
                invoice_type_id: 1,
                user_id: this.userID,
                is_default: true
            };
            this.form.title = this.$t("pages.ecommerce.user.address.newAddress");
            this.showModal(this.$refs.addUserAddressModal);
        },
        fetchUserAddress(record) {
            this.form.updateStatus = true;
            this.form.title = this.$t("pages.ecommerce.user.address.editAddress");
            this.axios.get(this.endpoints['user_address'] + '/' + record.id).then(response => {
                let data = response.data.data;
                this.form.data = data;
                this.remoteMethod('options', 'countries', this.countries[data.country_id].label);
                this.countryChanged(true);
                this.showModal(this.$refs.addUserAddressModal);
            });
        },
        onSubmit() {
            this.$refs.userAddressForm.validate((valid) => {
                if (valid) {
                    if (this.prepareUserAddressFormData()) {
                        this.form.loading = true;

                        if (this.form.data.id) {
                            this.axios.put(this.endpoints['user_address'] + '/' + this.form.data.id, this.form.data).then(response => {
                                this.onResponse(response.data, () => {
                                    this.refreshTable();
                                    this.hideModal(this.$refs.addUserAddressModal);
                                }, () => {
                                    this.form.loading = false;
                                });
                            }).catch(error => {
                                this.onResponseFailure(error.response.data, () => {
                                    this.form.loading = false;
                                });
                            });
                        } else {
                            this.axios.post(this.endpoints['user_address'], this.form.data).then(response => {
                                this.onResponse(response.data, () => {
                                    this.refreshTable();
                                    this.hideModal(this.$refs.addUserAddressModal);
                                }, () => {
                                    this.form.loading = false;
                                });
                            }).catch(error => {
                                this.onResponseFailure(error.response.data, () => {
                                    this.form.loading = false;
                                });
                            });
                        }
                    }
                } else {
                    return false;
                }
            });
        },
        deleteRecord(id) {
            this.$store.dispatch("ecommerce/user/address/delete", {
                id: id
            }).then((successDeleted) => {
                this.selectedRowKeys = this.selectedRowKeys.filter(x => !successDeleted.includes(x));
            });
        },
        handleClickAction(name) {
            switch (name) {
                case "refresh":
                    this.refreshTable();
                    break;

                case "new":
                    this.newUserAddress();
                    break;

                case "delete":
                    this.deleteRecord(this.selectedRowKeys.flat());
                    break;

                default:
                    break;
            }
        },
        refreshTable() {
            this.$store.dispatch("ecommerce/user/address/refresh");
        },
        handleTableChange(pagination, filterData) {
            this.$store.dispatch("ecommerce/user/address/get", {
                page: pagination,
                filterData: filterData
            });
        },
        handleSelectedRow(record) {
            this.selectedRowKeys = record;
        },
        prepareUserAddressFormData() {
            let formData = this.form.data;

            if (formData.invoice_type_id == 1) {
                delete formData.company_name;
                delete formData.company_tax_office;
            }

            this.form.data = formData

            return true;
        },
        countryRemoteMethod(query) {
            this.remoteMethod('options', 'countries', query);
        },
        stateRemoteMethod(query) {
            this.remoteMethod('stateOptions', 'states', query);
        },
        cityRemoteMethod(query) {
            this.remoteMethod('cityOptions', 'cities', query);
        },
        remoteMethod(optionKey, listKey, query) {
            let options = [];

            for (let index in this[listKey]) {
                let item = this[listKey][index];

                if (item.label.toLocaleLowerCase('tr-TR').indexOf(query.toLocaleLowerCase('tr-TR')) > -1) {
                    options.push(item);
                }
            }

            this.country[optionKey] = options;
        },
        countryChanged(initState = false) {
            if (!initState) {
                this.form.data.state_id = undefined;
                this.form.data.city_id = undefined;
            }

            this.$store.dispatch('localization/country/state/get', {
                page: {pageSize: 9999},
                filterData: {
                    country_id: this.form.data.country_id
                }
            }).then((states) => {
                let tempStates = {};

                states.forEach((state) => {
                    tempStates[state.id] = {
                        label: state.name,
                        value: state.id
                    }
                })

                this.states = tempStates;

                if (initState) {
                    this.stateChanged(true);
                    this.remoteMethod('stateOptions', 'states', this.states[this.form.data.state_id].label);
                }
            })
        },
        stateChanged(initCity = false) {
            if (!initCity) {
                this.form.data.city_id = undefined;
            }

            this.$store.dispatch('localization/country/city/get', {
                filterData: {
                    state_id: this.form.data.state_id
                }
            }).then((cities) => {
                let tempCities = {};

                cities.forEach((city) => {
                    tempCities[city.id] = {
                        label: city.name,
                        value: city.id
                    }
                })

                this.cities = tempCities;

                if (initCity) {
                    this.remoteMethod('cityOptions', 'cities', this.cities[this.form.data.city_id].label);
                }
            });
        },
        getText(invoiceTypeID, column){
            let returnText = '';

            let textSchema = {
                type: {
                    1: this.$t('common.individual'),
                    2: this.$t('common.corporate')
                },
                identificationNumber: {
                    1: this.$t('pages.ecommerce.user.address.cols.identificationNumber'),
                    2: this.$t('pages.ecommerce.user.address.cols.taxNumber')
                }
            }

            if(textSchema[column] && textSchema[column][invoiceTypeID]){
                returnText = textSchema[column][invoiceTypeID];
            }

            return returnText;
        }
    }
}
</script>

<style scoped>

</style>